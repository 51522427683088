// extracted by mini-css-extract-plugin
export var noMargin = "n_p5 g_dg";
export var carouselCol = "n_p6 g_dH";
export var carouselWrapper = "n_lR g_lR g_cD g_cH g_cS g_bD g_bP";
export var carouselImageWrapper = "n_lS g_lS g_bP g_bD";
export var carouselContainer = "n_p7 g_dH";
export var carouselContainerFull = "n_p8 g_fT";
export var carouselImg = "n_S g_R g_bD g_bP g_cV";
export var imageTextWrapperLeft = "n_lL g_lL g_lK g_b6 g_cX";
export var imageTextWrapperCenter = "n_lM g_lM g_lK g_b6 g_cX";
export var imageTextWrapperRight = "n_lN g_lN g_lK g_b6 g_cX";
export var imageTextWrapperHidden = "n_lJ g_lJ";
export var imageAltSmall = "n_p9 g_lP";
export var imageAltNormal = "n_qb g_lP";
export var imageAltLarge = "n_qc g_lP";
export var imageTextSmall = "n_qd g_lQ";
export var imageTextNormal = "n_qf g_lQ";
export var imageTextLarge = "n_qg g_lQ";