// extracted by mini-css-extract-plugin
export var carouselContainer = "z_p7 g_bD g_bP g_C g_b5";
export var carouselContainerCards = "z_tL z_p7 g_bD g_bP g_C g_b5";
export var carouselContainerSides = "z_tM g_bD g_bP g_b5";
export var prevCarouselItem = "z_tN g_bD g_bP g_b6 g_br";
export var prevCarouselItemL = "z_tP z_tN g_bD g_bP g_b6 g_br";
export var moveInFromLeft = "z_tQ";
export var prevCarouselItemR = "z_tR z_tN g_bD g_bP g_b6 g_br";
export var moveInFromRight = "z_tS";
export var selectedCarouselItem = "z_tT g_bD g_bP g_b5 g_C";
export var selectedCarouselItemL = "z_tV z_tT g_bD g_bP g_b5 g_C";
export var selectedCarouselItemR = "z_tW z_tT g_bD g_bP g_b5 g_C";
export var nextCarouselItem = "z_tX g_bD g_bP g_b6 g_br";
export var nextCarouselItemL = "z_tY z_tX g_bD g_bP g_b6 g_br";
export var nextCarouselItemR = "z_tZ z_tX g_bD g_bP g_b6 g_br";
export var arrowContainer = "z_t0 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainer = "z_t1 z_t0 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainerHidden = "z_t2 z_t1 z_t0 g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var nextArrowContainer = "z_t3 z_t0 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var carouselContainerProducts = "z_t4";
export var nextArrowContainerHidden = "z_t5 z_t3 z_t0 g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var arrow = "z_lp g_b6";
export var prevArrow = "z_t6 z_lp g_b6";
export var nextArrow = "z_t7 z_lp g_b6";
export var carouselIndicatorContainer = "z_t8 g_bD g_fz g_cp g_cD g_cH g_cS g_cM g_b6";
export var btnsContainer = "z_t9 g_bD g_cD g_cJ";
export var carouselText = "z_vb g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var carouselTextInactive = "z_vc z_vb g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var indicator = "z_vd g_db";
export var carouselIndicator = "z_vf z_vd g_db";
export var carouselIndicatorSelected = "z_vg z_vd g_db";
export var arrowsContainerTopRight = "z_vh g_b6 g_cp g_cG";
export var arrowsContainerBottomLeft = "z_vj g_b6 g_cp g_cG";
export var arrowsContainerSides = "z_vk g_b6 g_cp g_cG";
export var smallArrowsBase = "z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainer = "z_vm z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrev = "z_vn z_vm z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerSmall = "z_vp z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrevSmall = "z_vq z_vp z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var icon = "z_pZ";
export var iconSmall = "z_vr";
export var multipleWrapper = "z_vs g_cG g_cJ g_C";
export var multipleImage = "z_vt g_cG";
export var sidesPrevContainer = "z_vv z_vm z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";
export var sidesNextContainer = "z_vw z_vm z_vl g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";