// extracted by mini-css-extract-plugin
export var customText = "p_qh g_fy g_dw g_dk";
export var videoIframeStyle = "p_pG g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var customImageWrapper = "p_qj g_dw g_dk g_b5";
export var customRow = "p_p0 g_cH g_C";
export var quoteWrapper = "p_hH g_cG g_cS g_dw g_dk";
export var quoteBar = "p_pL g_bP";
export var masonryImageWrapper = "p_pS";
export var title = "p_qk";
export var Title3Small = "p_ql w_ql w_rC w_rL";
export var Title3Normal = "p_qm w_qm w_rC w_rM";
export var Title3Large = "p_qn w_qn w_rC w_rN";