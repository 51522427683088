// extracted by mini-css-extract-plugin
export var alignLeft = "v_qp g_gm g_cK g_fy";
export var alignCenter = "v_cS g_gn g_cH g_fz";
export var alignRight = "v_qq g_gp g_cL g_fB";
export var menuContainer = "v_rz g_fW";
export var menuContainerFull = "v_rB g_fT";
export var menuMainHeader = "v_km g_km g_bD g_d6";
export var menuComponentWrapper = "v_kq g_kq g_dy";
export var menuComponentWrapperDesign2 = "v_kr g_kr g_dC";
export var menuComponentText = "v_kn g_kn g_dr";
export var menuComponentTextDesign2 = "v_kp g_kp g_dw";
export var menuImageWrapperDesign2 = "v_ks g_ks g_bD g_b5";