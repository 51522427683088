// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rg g_hp g_dw";
export var heroHeaderCenter = "t_hq g_hq g_dw g_fz";
export var heroHeaderRight = "t_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "t_rh g_hl g_dz";
export var heroParagraphCenter = "t_hm g_hm g_dz g_fz";
export var heroParagraphRight = "t_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "t_rj g_p g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "t_rk g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "t_rl g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "t_rm g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "t_rn g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "t_rp w_rp";
export var heroExceptionNormal = "t_rq w_rq";
export var heroExceptionLarge = "t_rr w_rr";
export var Title1Small = "t_rs w_rs w_rC w_rD";
export var Title1Normal = "t_rt w_rt w_rC w_rF";
export var Title1Large = "t_rv w_rv w_rC w_rG";
export var BodySmall = "t_rw w_rw w_rC w_rW";
export var BodyNormal = "t_rx w_rx w_rC w_rX";
export var BodyLarge = "t_ry w_ry w_rC w_rY";