// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_qK g_gm g_cK g_fy";
export var alignLeft = "s_qp g_gm g_cK g_fy";
export var alignDiscCenter = "s_qL g_gn g_cH g_fz";
export var alignCenter = "s_cS g_gn g_cH g_fz";
export var alignDiscRight = "s_qM g_gp g_cL g_fB";
export var alignRight = "s_qq g_gp g_cL g_fB";
export var footerContainer = "s_qN g_fW g_cZ";
export var footerContainerFull = "s_qP g_fT g_cZ";
export var footerHeader = "s_kZ g_kZ";
export var footerTextWrapper = "s_qQ g_bD";
export var footerDisclaimerWrapper = "s_k4 g_k4 g_dn";
export var badgeWrapper = "s_qR g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "s_qS undefined";
export var footerDisclaimerLeft = "s_qT undefined";
export var verticalTop = "s_qV g_cD g_cM g_cR g_cP";
export var firstWide = "s_qW";
export var disclaimer = "s_qX g_cD g_cM";
export var socialDisclaimer = "s_qY";
export var left = "s_qZ";
export var wide = "s_q0 g_dC";
export var right = "s_q1 g_cN";
export var line = "s_gh g_gj g_dy";
export var badgeDisclaimer = "s_q2 g_cG g_cS g_cM";
export var badgeContainer = "s_q3 g_cD g_cL g_cS";
export var badge = "s_q4";
export var padding = "s_q5 g_fb";
export var end = "s_q6 g_cL";
export var linkWrapper = "s_q7 g_fF";
export var link = "s_v g_fF";
export var colWrapper = "s_q8 g_dB";
export var consent = "s_f g_f g_cG g_cS";
export var media = "s_q9 g_cB g_fC";
export var itemRight = "s_rb";
export var itemLeft = "s_rc";
export var itemCenter = "s_rd";
export var exceptionWeight = "s_rf w_r3";