// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_gN g_gN g_cD g_cL g_cM";
export var navbarDividedRight = "x_gP g_gP g_cD g_cM";
export var menuLeft = "x_sH g_gL g_cD g_fz g_cS g_cM";
export var menuRight = "x_sJ g_gL g_cD g_fz g_cS g_cM";
export var menuCenter = "x_sK g_gM g_gL g_cD g_fz g_cS g_bD g_cH g_cM";
export var menuDivided = "x_n5 g_gM g_gL g_cD g_fz g_cS g_bD g_cH";
export var navbarItem = "x_n6 g_cB";
export var navbarLogoItemWrapper = "x_gT g_gT g_cG g_cS";
export var burgerToggleVisibleOpen = "x_sL g_g7 g_cC g_b5 g_cw";
export var burgerToggleVisible = "x_sM g_g7 g_cC g_b5 g_cw";
export var burgerToggle = "x_sN g_g7 g_cC g_b5 g_cw g_b5";
export var burgerToggleOpen = "x_sP g_g7 g_cC g_b5 g_cw g_b9";
export var burgerInput = "x_sQ g_gY g_bD g_bP g_cC g_dg g_fp g_cl g_b6 g_cg g_cf g_cb g_ch";
export var burgerLine = "x_sR g_gX";
export var burgerMenuLeft = "x_sS g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuRight = "x_sT g_g3 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuCenter = "x_sV g_g4 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuDivided = "x_sW g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var secondary = "x_sX g_cG g_cS";
export var staticBurger = "x_sY";
export var menu = "x_sZ";
export var navbarDividedLogo = "x_s0";
export var nav = "x_s1";